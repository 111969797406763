import { axiosInstance } from '@utils/api';
import { API } from '@/constants';

const login = ({ request, source }) => {
  const url = API.login;
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
    auth: request.auth,
    cancelToken: source.token,
  };

  return axiosInstance.get(url, config);
};

export default {
  login,
};
