export const API = {
  availableSites: '/AvailableSites',
  calibrationDetection: '/api/Calibration/Detection/',
  login: '/apis/master/Login/',
  sensorHealth: '/api/Hub/Management/SensorHealth',
};
export const DATE_FORMAT = {
  defaultApiReq: "yyyy-LL-dd'T'HH:mm:ss",
};

export const MAC_ADDRESS_MASK = [
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
  ':',
  /[0-9A-Fa-f]/,
  /[0-9A-Fa-f]/,
];

export const STORAGE = window.localStorage;
