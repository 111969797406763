import React from 'react';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import AuthProvider from '@contexts/AuthProvider';
import SensorsProvider from '@contexts/SensorsProvider';
import SitesProvider from '@contexts/SitesProvider';
import PrivateRoute from '@components/PrivateRoute';

import './scss/style.scss';

const Layout = React.lazy(() => import('@components/ui/Layout'));
const Login = React.lazy(() => import('@pages/Login'));

function App() {
  return (
    <Container fluid className="app-body">
      <BrowserRouter>
        <AuthProvider>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRoute path="/">
                <SitesProvider>
                  <SensorsProvider>
                    <Layout />
                  </SensorsProvider>
                </SitesProvider>
              </PrivateRoute>
              <Redirect from="*" to="/" />
            </Switch>
          </React.Suspense>
        </AuthProvider>
      </BrowserRouter>
    </Container>
  );
}

export default App;
