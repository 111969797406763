import axios, { CancelToken } from 'axios';
import snakeCase from 'lodash.snakecase';

export const axiosInstance = axios.create({
  baseURL: '/api-master',
  headers: {
    'Content-type': 'application/json',
  },
});

export const apiCallWrapper =
  ({
    user,
    apiRequest,
    requestPayload,
    setError,
    setLoading,
    onSuccess,
    onLoading,
    onError,
    onComplete,
  }) =>
  async (payload) => {
    if (setError) setError(false);
    if (setLoading) setLoading(true);

    const request = {};
    if (user) request.params = { ...user.getAuth() };
    if (requestPayload) {
      Object.entries(requestPayload).forEach(([key, value]) => {
        if (value && value.length > 0)
          value.forEach((param) => {
            if (payload) {
              request[key] = { ...request[key] };
              request[key][snakeCase(param)] = payload[param];
            }
          });
      });
    }
    const source = CancelToken.source();

    try {
      if (onLoading) onLoading({ payload, request, source });
      const response = await apiRequest({ request, source });
      if (onSuccess) onSuccess(response, { payload, request, source });
    } catch (error) {
      if (setError) setError(true);
      if (onError) onError(error, { payload, request, source });
    } finally {
      if (setLoading) setLoading(false);
      if (onComplete) onComplete();
    }
  };

export default axiosInstance;
