import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import authApi from '@apis/auth';
import { STORAGE } from '@/constants';
import { btoa, atob } from '@/utils';
import { apiCallWrapper } from '@utils/api';

function User(id, username, password, name) {
  this.id = id;
  this.username = username;
  this.password = password;
  this.name = name;
}

User.prototype.getAuth = function getAuth() {
  return {
    user: this.username,
    pass: this.password,
  };
};

export const AuthContext = createContext({
  authenticated: false,
  error: false,
  loading: true,
  login: null,
  logout: null,
  user: User,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    try {
      const storageUserBase64 = STORAGE.getItem('user');
      const storageUserStr = atob(storageUserBase64);
      const newUser = JSON.parse(storageUserStr);
      const { id, username, password, name } = newUser;
      setUser(new User(id, username, password, name));
    } catch {
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) setAuthenticated(true);
  }, [user]);

  const logout = () => {
    setAuthenticated(false);
    setUser(null);
    STORAGE.clear();
  };

  const login = apiCallWrapper({
    apiRequest: authApi.login,
    requestPayload: { auth: ['username', 'password'] },
    setError,
    setLoading,
    onSuccess: ({ data }) => {
      const { id, username, password, name } = data;
      const newUser = new User(id, username, password, name);
      setUser(newUser);
      STORAGE.setItem('user', btoa(newUser));
    },
  });

  const contextValues = useMemo(
    () => ({
      authenticated,
      error,
      loading,
      user,
      login,
      logout,
    }),
    [authenticated, error, loading, user]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
}
