import React, { createContext, useContext, useMemo, useState } from 'react';
import { useAuth } from '@contexts/AuthProvider';
import sensorsApi from '@apis/sensors';
import { apiCallWrapper } from '@utils/api';

function Sensor(id, status) {
  this.id = id;
  this.online = status === 'online';
  this.offline = status === 'offline' || !status;
}

Sensor.parseFromApi = (data = []) =>
  data.map((item) => {
    const { sensorId, status } = item;
    return new Sensor(sensorId, status);
  });

export const SensorsContext = createContext({
  error: false,
  loading: false,
  getSensorHealth: null,
});

export const useSensors = () => useContext(SensorsContext);

export default function SensorsProvider({ children }) {
  const { user } = useAuth();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sensors, setSensors] = useState([]);

  const getSensorHealth = apiCallWrapper({
    user,
    apiRequest: sensorsApi.sensorHealth,
    requestPayload: { params: ['siteId'] },
    setError,
    setLoading,
    onSuccess: ({ data }) => setSensors(Sensor.parseFromApi(data)),
  });

  const contextValues = useMemo(
    () => ({
      error,
      loading,
      sensors,
      getSensorHealth,
    }),
    [error, loading, sensors]
  );

  return (
    <SensorsContext.Provider value={contextValues}>
      {children}
    </SensorsContext.Provider>
  );
}
