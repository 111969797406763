export const atob = (data) => Buffer.from(data, 'base64').toString('utf8');

export const btoa = (data) =>
  Buffer.from(JSON.stringify(data)).toString('base64');

export const buttonize = (handlerFn) => ({
  role: 'button',
  onClick: handlerFn,
  onKeyDown: (e) => {
    if (e.keycode === 113) handlerFn(e);
  },
});
