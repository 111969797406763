import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@contexts/AuthProvider';

const PrivateRoute = ({ children, ...props }) => {
  const { authenticated, loading } = useAuth();
  return (
    <Route
      {...props}
      render={({ location }) => {
        if (authenticated) {
          return children;
        }

        return loading ? (
          <div>Loading...</div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default React.memo(PrivateRoute);
