import { axiosInstance } from '@utils/api';
import { API } from '@/constants';

const availableSites = ({ request, source }) => {
  const url = API.availableSites;
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
    params: request.params,
    cancelToken: source.token,
  };

  return axiosInstance.get(url, config);
};

export default {
  availableSites,
};
