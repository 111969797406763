import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '@contexts/AuthProvider';
import sitesApi from '@apis/sites';
import { STORAGE } from '@/constants';
import { btoa, atob } from '@/utils';
import { apiCallWrapper } from '@utils/api';

function Site(id, name) {
  this.id = id;
  this.name = name;
}

export const SitesContext = createContext({
  error: false,
  loading: false,
  site: Site,
  sites: [],
  getAvailableSites: null,
  selectSite: null,
});

export const useSites = () => useContext(SitesContext);

export default function SitesProvider({ children }) {
  const { user } = useAuth();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [site, setSite] = useState(new Site());
  const [sites, setSites] = useState([]);

  useEffect(() => {
    try {
      const storageSiteBase64 = STORAGE.getItem('site');
      const storageSiteStr = atob(storageSiteBase64);
      const newSite = JSON.parse(storageSiteStr);
      setSite(new Site(newSite.id, newSite.name));
    } catch {
      setSite(new Site());
    } finally {
      setLoading(false);
    }
  }, []);

  const getAvailableSites = apiCallWrapper({
    user,
    apiRequest: sitesApi.availableSites,
    setError,
    setLoading,
    onSuccess: ({ data }) => setSites(data),
  });

  const selectSite = (selectedSite) => {
    const newSite = new Site(selectedSite.id, selectedSite.name);
    setSite(newSite);
    STORAGE.setItem('site', btoa(newSite));
  };

  const contextValues = useMemo(
    () => ({
      error,
      loading,
      site,
      sites,
      getAvailableSites,
      selectSite,
    }),
    [error, loading, site, sites]
  );

  return (
    <SitesContext.Provider value={contextValues}>
      {children}
    </SitesContext.Provider>
  );
}
